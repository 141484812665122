exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-tsx": () => import("./../../../src/pages/elements.tsx" /* webpackChunkName: "component---src-pages-elements-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kataloge-tsx": () => import("./../../../src/pages/kataloge.tsx" /* webpackChunkName: "component---src-pages-kataloge-tsx" */),
  "component---src-pages-outdoor-tsx": () => import("./../../../src/pages/outdoor.tsx" /* webpackChunkName: "component---src-pages-outdoor-tsx" */),
  "component---src-pages-schirme-tsx": () => import("./../../../src/pages/schirme.tsx" /* webpackChunkName: "component---src-pages-schirme-tsx" */)
}

